.faq{padding: 90px 0;}
.faqsWrap{display: flex;justify-content: space-between;align-items: center;}
.faqimg{flex: 1;}
.allFaq{flex: 1;}
.faqQuestion{border-top:  1px solid #c2c2c2;border-bottom:  1px solid #c2c2c2;}
.faqQuestion .question{padding: 15px 10px;cursor: pointer; display: flex;align-items: center;justify-content: space-between;}
.question .arw {transform: rotate(45deg);}
.faqQuestion .answer{height: 0;opacity: 0;visibility: hidden;}
.faqQuestion.active .answer{height: 100%;opacity: 1;transition: 0.5s;padding: 20px 0;visibility: unset;}
.faqQuestion.active .question{border-bottom: 1px solid #c2c2c2;}
.faqQuestion.active .question p{color: #466759;}
.faqQuestion.active .question .arw {transform: rotate(224deg);}


@media screen and (max-width: 860px) {
    .faq{padding: 30px 0;}
    .faqimg{display: none;}

}