/* discoverourtreatment */
.discoverourtreatment{background: #506E61;padding: 34px 0 60px;}
.discoverourtreatment .sectionHeading::before{background: #d9d9d9;}
.discoverourtreatment .sectionHeading::after{background: #fff;}
.ourTreatmentsWrap{margin-top: 40px;display: flex;gap: 23px;justify-content: center;flex-wrap: wrap;}
.treatmentType{background: #fff;overflow: hidden; display: flex;justify-content: space-between; padding: 0px; border-radius: 8px;max-width: 600px;width: 600px;}
.treatmentType .treatmentDetails{padding: 28px  20px 10px;}
/* .treatmentType:hover{background: #ececec;transition: 0.5s;} */
.treatHeading{white-space: nowrap; display: flex;align-items: center;gap: 15px;cursor: pointer;margin-bottom: 20px;}
.treatmentType figure{width: 295px;height: 100%;flex-shrink: 0;}
.treatmentType figure img{height: 100%;}
/* .treatmentDetails .subcategory{display: flex;gap: 10px;} */
/* .treatmentDetails .subcategory li:not(:last-child){border-right: 2px solid #333;padding: 0 20px;padding-left: 0;} */


@media screen and (max-width: 680px) {
.ourTreatmentsWrap{margin-top: 25px;}
.treatmentType .treatmentDetails{padding: 10px;flex: 1;}
.treatmentType{width: 100%;max-width: unset;}
.treatHeading{gap: 5px;margin-bottom: 10px;}
.treatHeading img{max-width: 6px;}
.treatmentType:nth-child(2n){flex-direction: row-reverse;}
.treatmentType figure{flex: 1;}
.treatmentDetails .subcategory li>a{display: flex;align-items: center;gap: 5px; margin-bottom: 5px;}
}
