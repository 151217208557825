.ourSuccessrate{width: 100%;background-image: linear-gradient(to bottom, rgb(92 92 92 / 52%), #000000ba), url(../../../public/assets/images/ourtrustbackgroundImg.jpg);background-repeat: no-repeat;background-size: cover;background-position: 50% 0%;background-attachment: fixed;padding: 50px 0 60px;}
.ourSuccessrate .sectionHeading::before{background: #d9d9d9;}
.ourSuccessrate .sectionHeading::after{background: #fff;}
.ourSuccessrate .successRate{margin-top: 50px; display: flex;background: #fff;border-radius: 10px;padding: 20px 0;}
.successRate li{flex: 1; border-right: solid 1px #828384;padding:  20px;}
.successRate li:last-child{border: none;}

@media screen and (max-width: 860px) {
    .ourSuccessrate .successRate{flex-direction: column;padding: 15px 0;margin-top: 10px;}
    .ourSuccessrate { background-position: 90% 160px; }
    .successRate li{padding: 10px 15px;border-bottom: solid 1px #828384;border-right:none}
    .successRate li:last-child{border-bottom: none;}
}