.ourmedicalBoard{padding: 90px 0;}
.teamOnBoardMembers{position: relative;}
.membersFlex{display: flex;gap: 20px;padding: 40px 30px;padding-left: 0; position: relative;overflow: auto;scroll-behavior: smooth;}
.teamOnBoardMembers .indMember{margin:5px; padding: 20px 20px 30px;width: 285px; height: 100%; background: #fff;border-radius: 15px;text-align: center;box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;}
.indMember figure{    width: 126px;height: 121px;margin: 0 auto;margin-bottom: 20px;}
.indMember figure img{border-radius: 50%;object-fit: contain;height: 100%;}
.indMember .doctorRole{height: 42px;}
.indMember .doctorspeci{height: 54px;}
.indMember .bordertop{border-top: dashed 1px #666;padding-top: 10px;}
.indMember:hover{background: #f1f1f1;cursor: pointer;}
.indMember:hover figure img{outline: 2px solid #466759;}

@media screen and (max-width: 860px) {
    .ourmedicalBoard{padding: 50px 0;}
    .teamOnBoardMembers .indMember{min-width: 280px; width: 100%;margin: 0 auto; flex-shrink: 0;}
    .indMember.active{width: 200px; transform: scale(1.1);transition: 0.3s;}
}