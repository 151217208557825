.main_banner {
    position: relative;
    width: 100%;
    display: block;
}
.main_banner .owl-carousel .owl-stage {
    display: flex;
}
.main_banner .owl-carousel.owl-drag .owl-item{
    height: 100%;
}
.cta_btn{
    font-size: 20px;
    font-weight: bold;
    padding: 15px 40px;
    border-radius: 50px;
    min-width:auto;
    margin-top: 30px;
}
.banner_area{
    display: flex;
    justify-content:space-between;
    /* align-items: center; */
    padding: 0 250px;
}

.banner_area img{
    width: auto !important;
}
.banner_area.grid_banner {
    padding: 0 30px 0 30px;
    display: grid;
    gap: 80px;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
.banner_area.grid_banner .img_top_padding{
    margin-top: 0;
    position: relative;
    top: 5px;
}
.banner_area img.fit {
    width: auto !important;
    /* max-width: 100%;
    object-fit: cover;
    height: 100%; */
}
.banner_area .banner_content {
    width: 100%;
    padding: 50px 0;
}
.bannerBg1{
    background: #ffcece;
}
.bannerBg2{
    background: #7dc6e6;
}
.bannerBg3{
    background: #b9e8d4;
}
.bannerBg4{
    background: #d0c5f0;
}
.bannerBg5{
    background: #f1d1c2;
}
.bannerBg6{
    background: #b8f0ff;
}
.banner_area .banner_content h4{
    font-size: 31px;
    color: #231f20;
    padding-bottom: 20px;
    margin-bottom: 30px;
    inline-size:560px;
    display: inline-block;
    position: relative;
    max-width: 430px;
}
.banner_area .banner_content h4::after{
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    content: '';
    height: 4px;
    background: #000;
}
.banner_area .banner_content p{
    font-size: 18px;
    color: #231f20;
    line-height: 30px;
}
.main_banner .owl-theme .owl-dots {
    margin-top: 0;
    position: absolute;
    bottom: -5%;
    left: 40%;
    transform: translate(85%, 50%);
    width: auto;
    display: flex;
}

.numberOfConsultationList{display:flex;display:-webkit-flex;align-items: center;margin:28px 0px 0px 0px;gap: 20px;}
.numberOfConsultationList li{display: flex;display: -webkit-flex;align-items: center;flex-direction: column;position: relative; padding-right: 30px;}
.numberOfConsultationList li::before{content:"";position: absolute;top:-7px;bottom:0;border:1px solid #333;height:117%;right:0;}
.numberOfConsultationList li:last-child::before{display: none;}
.numberOfConsultationList li .numbers{color:#344E43;}
.numberOfConsultationList li .numbers.textfhonts{
    font-size: 25px;
}
.numberOfConsultationList li .small-text{color:#272727; font-size: 16px;}
.numberOfConsultationList li .consultsWithDoc{color:#231f20;text-align: center;}
.main_banner .owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 1px 7px;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 50px;
}
.main_banner .owl-theme .owl-dots .owl-dot.active span, .main_banner .owl-theme .owl-dots .owl-dot:hover span {
    background: #466759;
}
.dextop_d_none{
    display: none;
}

.ctn_bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
}

.ctn_bottom .mobile_cta_btn {
    border: 1px solid #506e61;
    display: block;
    padding: 6px 20px;
    color: #fff;
    border-radius: 30px;
    margin-top: 20px;
    z-index: 1;
    background: #506e61;
    text-transform: uppercase;
    width: 80%;
    font-size: 25px;
    margin: 0 auto;
    transition: all 0.5s ease;
}
.ctn_bottom .mobile_cta_btn:hover{
    background: #FFF;
    color: #466759;
}
@media screen and (max-width: 1600px) {
    .banner_area{
        padding: 0 30px 0 30px;
    }

}
@media screen and (max-width: 1200px) {
    .banner_area .banner_content ul{
        padding: 0;
        margin: 0;
    }
    .banner_area.grid_banner .img_top_padding {
        margin-top: 0;
        position: relative;
        top:0px;
    }
    .banner_area.grid_banner{
        gap: 20px;
    }
    .banner_area.grid_banner .img_top_padding img{
        max-width: 100%;
    }
    .banner_area.grid_banner .img_top_padding img.fit {
        height: 100%;
        width: 100%;
        object-fit: cover;
        margin-top: 20px;
        position: absolute;
        right: -20px;
        bottom: 0;
        max-width: 454px;
    }
    .numberOfConsultationList li .numbers.textfhonts {
        font-size: 15px;
    }
    .numberOfConsultationList li .small-text {
        font-size: 12px;
    }
}
@media screen and (max-width: 991px) {
    .banner_area .banner_content ul{
        padding: 0;
        margin: 0;
    }
    .banner_area.grid_banner .img_top_padding {
        margin-top: 0;
        position: relative;

    }
    .banner_area.grid_banner{
        gap: 20px;
    }
    .banner_area.grid_banner .img_top_padding img{
        max-width: 100%;
    }
    .banner_area.grid_banner .img_top_padding img.fit {
        height: 100%;
        width: 100%;
        object-fit: cover;
        margin-top: 20px;
        position: absolute;
        right: -20px;
        bottom: 0;
        max-width: 454px;
    }
    .numberOfConsultationList li .numbers.textfhonts {
        font-size: 15px;
    }
    .numberOfConsultationList li .small-text {
        font-size: 12px;
    }
}
@media screen and (max-width: 860px) {
    .numberOfConsultationList li .consultsWithDoc{
        inline-size: 100px;
    }
    .numberOfConsultationList li{width:33%;}
    .numberOfConsultationList li:first-child{padding-left: 0;}
    .main_banner .owl-theme .owl-dots {left: 30%;bottom: -3%;}
    /* Rizwan */
    .banner_area.grid_banner {
        padding: 0 30px 0 30px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .banner_area.grid_banner .img_top_padding{
        margin-top: 50px;
    }
    .banner_area {
        flex-direction: column;
        gap:50px;
        padding: 0;
        /* height: 100vh; */
    } 


    .banner_area .banner_content{
        padding: 30px 20px 0 20px;
    }
    .banner_area .banner_content h4{
        font-size: 25px;
        inline-size: 400px;
    }
    .banner_area .banner_content p{
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    .mobile_d_none{
        display: none !important;
    }
    .dextop_d_none {
        display: block;
    }
    .banner_area.grid_banner {
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        overflow: hidden;
    }
    .banner_area.grid_banner .img_top_padding {
        margin-top: 50px;
        max-width: 450px;
        margin: 0 auto;
    }
    .banner_area.grid_banner .img_top_padding img.fit {
        height: 100%;
        width: 100% !important;
        object-fit: cover;
        margin-top: 0;
        position: relative;
        right: 0;
        bottom: 0;
        max-width: 418px;
        margin: 0 auto;
    }
    .banner_area .banner_content {
        padding: 30px 0;
    }
    .banner_area .banner_content p {
        font-size: 13px;
        line-height: 24px;
    }

}


@media screen and (max-width: 400px) {
    .banner_area .banner_content h4{
        font-size: 18px;
        inline-size: 100%;
    }
    .cta_btn{
        font-size: 16px !important;
        padding: 10px 20px;
    }
    .banner_area{
        max-height: 580px;
    }
    .ctn_bottom .mobile_cta_btn{
        font-size: 16px;
    }
}
@media screen and (max-width: 480px) {
    .main_banner .owl-theme .owl-dots {
        left: 10%;
    }

    /* .img1_ban{
        width:250px !important;
    } */
}