.howItWorks{padding-top: 30px;} 
.howItworksWrap{display: flex;gap: 20px;padding: 40px 0;}
.steps{flex: 1;}
.steps .stepsDetails{position: relative;text-align: center;border-radius: 10px;background: #fff;box-shadow: 0 3px 10px rgba(0,0,0,.3);padding: 50px 20px;min-height: 205px;height: 100%;max-height: 250px;}
.steps .stepsDetails:hover{background: #F5F5F5;}
.stepsIcon img{max-width: 60px;}
.sectionHeading{text-transform: none;}
 @media screen and (max-width: 860px) {
    .howItworksWrap{flex-direction: column;gap: 30px;padding: 20px 0 40px;}
    .howItworksWrap .steps{display: flex;gap: 14px;position: relative;}
    .howItworksWrap .steps:not(:last-child):after{content: '';position: absolute;background: #d9d9d9;height: 50px;bottom: -17px;left: 20px;width: 3px;}
    .steps .stepsDetails{padding: 15px;min-height: unset;}
    .stepsDetails .dblock{display: unset;}
    .stepsIcon img{max-width: 39px;}

 }
