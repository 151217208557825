
.whatWetreat{padding: 40px 0;}
.whatWeOffer{position: relative;}
.whatWeOffer ul{display: flex;justify-content: space-between; overflow: auto;gap:15px;margin-top: 30px;}
.whatWeOffer ul::-webkit-scrollbar{display: none;}
.whatWeOffer ul .offerImages{height: 210px;width: 235px;border-radius: 8px;margin-bottom: 20px;object-fit: cover}

@media screen and (max-width: 860px) {
    .whatWetreat{padding-top: 100px;}
    .whatWeOffer ul{justify-content: flex-start;gap: 12px;}
    .whatWeOffer ul .offerImages{height: 142px;width: 142px;border-radius: 8px;margin-bottom: 12px;}
}