.patientStories{padding: 60px 0;}
.patientRelative{position: relative;}
.patientStoriesWrap{display: flex;overflow: auto;gap: 20px;overflow-y: hidden;position: relative;padding-bottom: 40px;justify-content: space-between;scroll-behavior: smooth;}
.patientStoriesWrap .indPatients {border-radius: 10px;display: flex;flex-direction: column;align-items: center;box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);transition: all 0.3s cubic-bezier(.25,.8,.25,1);background: #fff;}
.patientstoryContent{ padding: 20px; background: #fff;}
.patientImg{width:100%;max-height: 250px;}
.indPatients .patientstory{padding-bottom: 10px;}
.patientImg img{ border-radius: 10px 10px 0 0; object-fit: contain; object-position: center; }

@media screen and (max-width: 860px) {
    .patientStories{padding: 30px 0;overflow: hidden;}
    .patientStoriesWrap{padding: 0;margin-bottom: 30px;overflow: auto;}
    .patientStoriesWrap .corowselarw{display: none;}
    .patientStoriesWrap .indPatients{flex: 0 0 100%;}
    .patientImg{right: 0; height:auto;}
    .patientImg img {width: 100%;}
    .patientstoryContent {border-radius: 0 0 10px 10px;}
}