.nirvasaApproach{padding: 90px 0;}
.foldsWrap{display: flex;align-items: center;}
ul.foldApproach_list{list-style:circle;}
.foldApproach_list li{
    text-align: left;
    /* display: flex; */
    gap: 10px; 
    font-size: 18px;
    font-weight: 300; 
    margin-bottom: 10px;
    position: relative;  
}
.textalg{
    text-align:left ;
}
@media screen and (max-width: 860px) {
    .nirvasaApproach {padding: 40px 0 0;}
    .foldsWrap{flex-direction: column;}
    /* ul.foldApproach_list {padding-left: 25px;} */
    ul.foldApproach_list li{font-size: 14px;}
}